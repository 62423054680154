function Operator (container, params) {
    try {
        this.container = container;

        if (params) {
            this.setParams(params);
        }

        this.init();

    } catch (error) {
        console.error(error);
    }
}

Operator.prototype = {
    container: undefined,
    notyf: undefined,
    remodal: undefined,
    partnerLabel: undefined,
    locationLabel: undefined,
    materialLabel: undefined,
    transportDateLabel: undefined,
    plateNumberLabel: undefined,
    regNumberLabel: undefined,
    commentLabel: undefined,
    statusLabel: undefined,
    saveButton: undefined,
    annexLabel: undefined,
    szallitolevelLabel: undefined,
    cmrLabel: undefined,
    merlegjegyLabel: undefined,
    anyagkiseroLabel: undefined,
    formRemodal: undefined,
    downloadLabel: undefined,
    statuses: {},
    transports: {},
    warehouses: {},
    sale_targets: {},
    baleNumLabel: undefined,
    transportLabel: undefined,
    warehouseLabel: undefined,
    saleTargetLabel: undefined,
    purchasePriceLabel: undefined,
    sellPriceLabel: undefined,
    priceUnits: {},
    utilizerOptions: {},
    utilizerLabel: undefined,
    init: function() {
        this.addDOMEvents();
        this.setElements();
        this.remodal = $('[data-remodal-id=operator-remodal]').remodal();        
    },
    addDOMEvents: function() {
        var _self = this;
        this.container.find('[data-purpose="search-button"]').on('click', function(){
            _self.getShipment();
        });

        $(document).on('click', '#savebutton-remodal', function(){
            _self.sendForm(_self.formRemodal);
        });

        $(document).on('change', '.file-remodal', function(){
            _self.uploadFile(this.files, $(this).data('type'));
        });
        
    },
    setElements: function() {
        this.notyf = new Notyf({
            delay:5000
        });
    },
    setParams: function(params) {

        if (params.plateNumberLabel) {this.setplateNumberLabel(params.plateNumberLabel);}
        if (params.commentLabel) {this.setcommentLabel(params.commentLabel);}
        if (params.transportDateLabel) {this.settransportDateLabel(params.transportDateLabel);}
        if (params.annexLabel) {this.setannexLabel(params.annexLabel);}
        if (params.szallitolevelLabel) {this.setszallitolevelLabel(params.szallitolevelLabel);}
        if (params.cmrLabel) {this.setcmrLabel(params.cmrLabel);}
        if (params.merlegjegyLabel) {this.setmerlegjegyLabel(params.merlegjegyLabel);}
        if (params.locationLabel) {this.setlocationLabel(params.locationLabel);}
        if (params.statusLabel) {this.setstatusLabel(params.statusLabel);}
        if (params.materialLabel) {this.setmaterialLabel(params.materialLabel);}
        if (params.regNumberLabel) {this.setregNumberLabel(params.regNumberLabel);}
        if (params.saveButton) {this.setsaveButton(params.saveButton);}
        if (params.statuses) {this.setStatuses(params.statuses);}
        if (params.partnerLabel) {this.setPartnerLabel(params.partnerLabel);}
        if (params.anyagkiseroLabel) {this.setanyagkiseroLabel(params.anyagkiseroLabel);}
        if (params.downloadLabel) {this.setdownloadLabel(params.downloadLabel);}

        if (params.transports) {this.setTransports(params.transports);}
        if (params.warehouses) {this.setWarehouses(params.warehouses);}
        if (params.saleTargets) {this.setSaleTargets(params.saleTargets);}
        if (params.priceUnits) {this.setpriceUnits(params.priceUnits);}

        if (params.transportLabel) {this.settransportLabel(params.transportLabel);}
        if (params.warehouseLabel) {this.setwarehouseLabel(params.warehouseLabel);}
        if (params.saleTargetLabel) {this.setsaleTargetLabel(params.saleTargetLabel);}
        if (params.purchasePriceLabel) {this.setpurchasePriceLabel(params.purchasePriceLabel);}
        if (params.sellPriceLabel) {this.setsellPriceLabel(params.sellPriceLabel);}

        if (params.annexNoLabel) {this.setannexNoLabel(params.annexNoLabel);}
        if (params.shippingNoLabel) {this.setshippingNoLabel(params.shippingNoLabel);}
        if (params.balanceNumberLabel) {this.setBalanceNumberLabel(params.balanceNumberLabel);}
        if (params.cmrNoLabel) {this.setcmrNoLabel(params.cmrNoLabel);}
        if (params.sentWeightLabel) {this.setsentWeightLabel(params.sentWeightLabel);}
        if (params.baleNumLabel) {this.setbaleNumLabel(params.baleNumLabel);}
        if (params.utilizerLabel) {this.setUtilizerLabel(params.utilizerLabel);}

        if (params.utilizerOptions) {this.setUtilizerOptions(params.utilizerOptions);}
    },
    setlocationLabel: function(locationLabel) {
        this.locationLabel = locationLabel;
    },
    setdownloadLabel: function(downloadLabel) {
        this.downloadLabel = downloadLabel;
    },
    setstatusLabel: function(statusLabel) {
        this.statusLabel = statusLabel;
    },
    setmaterialLabel: function(materialLabel) {
        this.materialLabel = materialLabel;
    },
    setregNumberLabel: function(regNumberLabel) {
        this.regNumberLabel = regNumberLabel;
    },
    setplateNumberLabel: function(plateNumberLabel) {
        this.plateNumberLabel = plateNumberLabel;
    },
    setcommentLabel: function(commentLabel) {
        this.commentLabel = commentLabel;
    },
    settransportDateLabel: function(transportDateLabel) {
        this.transportDateLabel = transportDateLabel;
    },
    setannexLabel: function(annexLabel) {
        this.annexLabel = annexLabel;
    },
    setszallitolevelLabel: function(szallitolevelLabel) {
        this.szallitolevelLabel = szallitolevelLabel;
    },
    setcmrLabel: function(cmrLabel) {
        this.cmrLabel = cmrLabel;
    },
    setmerlegjegyLabel: function(merlegjegyLabel) {
        this.merlegjegyLabel = merlegjegyLabel;
    },
    setsaveButton: function(saveButton) {
        this.saveButton = saveButton;
    },

    setStatuses: function(statuses) {
        this.statuses = statuses;
    },
    setTransports: function(transports) {
        this.transports = transports;
    },
    setWarehouses: function(warehouses) {
        this.warehouses = warehouses;
    },
    setSaleTargets: function(saleTargets) {
        this.saleTargets = saleTargets;
    },
    setpriceUnits: function(priceUnits) {
        this.priceUnits = priceUnits;
    },

    setPartnerLabel: function(partnerLabel) {
        this.partnerLabel = partnerLabel;
    },
    setanyagkiseroLabel: function(anyagkiseroLabel) {
        this.anyagkiseroLabel = anyagkiseroLabel;
    },

    setannexNoLabel: function(annexNoLabel) {
        this.annexNoLabel = annexNoLabel;
    },
    setshippingNoLabel: function(shippingNoLabel) {
        this.shippingNoLabel = shippingNoLabel;
    },
    setBalanceNumberLabel: function(balanceNumberLabel) {
        this.balanceNumberLabel = balanceNumberLabel;
    },
    setcmrNoLabel: function(cmrNoLabel) {
        this.cmrNoLabel = cmrNoLabel;
    },
    setsentWeightLabel: function(sentWeightLabel) {
        this.sentWeightLabel = sentWeightLabel;
    },
    setbaleNumLabel: function(baleNumLabel) {
        this.baleNumLabel = baleNumLabel;
    },

    settransportLabel: function(transportLabel) {
        this.transportLabel = transportLabel;
    },
    setwarehouseLabel: function(warehouseLabel) {
        this.warehouseLabel = warehouseLabel;
    },
    setsaleTargetLabel: function(saleTargetLabel) {
        this.saleTargetLabel = saleTargetLabel;
    },
    setpurchasePriceLabel: function(purchasePriceLabel) {
        this.purchasePriceLabel = purchasePriceLabel;
    },
    setsellPriceLabel: function(sellPriceLabel) {
        this.sellPriceLabel = sellPriceLabel;
    },
    setUtilizerOptions: function(utilizerOptions) {
        this.utilizerOptions = utilizerOptions;
    },
    setUtilizerLabel: function(utilizerLabel) {
        this.utilizerLabel = utilizerLabel;
    },
    getShipment: function() {

        var _self = this;

        var expression = this.container.find('.regnumber').val();

        $.ajax({
            url:ajax_controller+'getShipmentByRegNumber',
            type:'post',
            dataType:'json',
            data:{string:expression},
            success: function(response) {
                if (response.success) {
                    _self.openRemodal(response.shipment, response.sale);
                    _self.formRemodal = $('#operator-remodal-form');
                } else {
                    _self.notyf.alert(response.msg);
                }
            }
        });
    },
    openRemodal: function(shipment, sale) {
        var _self = this;
        var options = '';
        var szallTip = '';
        var raktar = '';
        var ertHely = '';
        var purchasePriceUnit = '';
        var sellPriceUnit = '';
        var utilizers = '';

        $.each(_self.statuses, function(key, value){
            options += '<option '+(key==shipment.status ? "selected" : "")+' value="'+key+'">'+value+'</option>'
        });

        $.each(_self.transports, function(key, value){
            szallTip += '<option '+(key==shipment.transport_type ? "selected" : "")+' value="'+key+'">'+value+'</option>'
        });

        $.each(_self.warehouses, function(key, value){
            raktar += '<option '+(key==sale.warehouse ? "selected" : "")+' value="'+key+'">'+value+'</option>'
        });

        $.each(_self.saleTargets, function(key, value){
            ertHely += '<option '+(key==sale.sale_target ? "selected" : "")+' value="'+key+'">'+value+'</option>'
        });

        $.each(_self.priceUnits, function(key, value){
            purchasePriceUnit += '<option '+(key==sale.purchase_price_unit ? "selected" : "")+' value="'+key+'">'+value+'</option>'
        });

        $.each(_self.priceUnits, function(key, value){
            sellPriceUnit += '<option '+(key==sale.sell_price_unit ? "selected" : "")+' value="'+key+'">'+value+'</option>'
        });

        $.each(_self.utilizerOptions, function(key, value){
            utilizers += '<option '+(key==sale.utilizer_id ? "selected" : "")+' value="'+key+'">'+value+'</option>'
        });

        var form_container = $('.operator-remodal');

        var form = $('<form id="operator-remodal-form" action="'+base_url+'save-operator-remodal/'+shipment.id+'">');

        var closeButton = '<button data-remodal-action="close" class="remodal-close"></button>';

        var partner = '<div class="row"><label for="partner-field">'+this.partnerLabel+':</label> <span class="load-field" id="partner-field">'+shipment.partner+'</span></div>';

        var location = '<div class="row"><label for="location-field">'+this.locationLabel+'</label> <span class="load-field" id="location-field">'+shipment.location+'</span></div>';

        var material = '<div class="row"><label for="materials-field">'+this.materialLabel+'</label> <span class="load-field" id="materials-field">'+shipment.materials+'</span></div>';

        var date = '<div class="row"><label for="date-field">'+this.transportDateLabel+':</label> <input type="text" value="'+shipment.date+'" class="field date-field" id="date-field" name="date" /></div>';        

        var plate = '<div class="row"><label for="plate-number">'+this.plateNumberLabel+':</label><input type="text" value="'+shipment.plate_number+'" class="field" id="plate-number-field" name="plate_number" /></div>';

        var reg_number = '<div class="row"><label for="regnumber-field">'+this.regNumberLabel+'</label> <span class="load-field" id="regnumber-field">'+shipment.reg_number+'</span></div>';

        var comment = '<div class="row"><label for="comment">'+this.commentLabel+':</label><br/><textarea type="text" class="field comment" id="comment" name="comment">'+shipment.comment+'</textarea></div>';

        var annexNo = '<div class="row annex-row"><label for="annex-number">'+this.annexNoLabel+'</label><br/><input type="text" value="" class="field annex-number" id="annex-number" name="annex_no" /></div>';

        var shippingNo = '<div class="row"><label for="shipping-number">'+this.shippingNoLabel+'</label><br/><input type="text" value="" class="field shipping-number" id="annex-number" name="shipping_no" /></div>';

        var balanceNumber = '<div class="row"><label for="balance-number">'+this.balanceNumberLabel+'</label><br/><input type="text" value="" class="field balance-number" id="balance-number" name="balance_number" /></div>';

        var cmrNo = '<div class="row"><label for="cmr-number">'+this.cmrNoLabel+'</label><br/><input type="text" value="" class="field cmr-number" id="cmr-number" name="cmr_no" /></div>';

        var sentWeight = '<div class="row"><label for="sent-weight">'+this.sentWeightLabel+'</label><br/><input type="text" value="" class="field sent-weight" id="sent-weight" name="sent_weight" /></div>';

        var baleNum = '<div class="row"><label for="bale-num">'+this.baleNumLabel+'</label><br/><input type="text" value="" class="field bale-num" id="bale-num" name="bale_num" /></div>';

        var statusDropdown = '<div class="row"><label for="status-field">'+this.statusLabel+'</label> '+
            '<select class="status-select" name="status">'+
                options
            +'</select>'+
        '</div>';        

        var transportDropdown = '<div class="row"><label for="transport-field">'+this.transportLabel+':</label> '+
            '<select class="transport-select" name="transport_type">'+
                szallTip
            +'</select>'+
        '</div>'; 

        var warehouseDropdown = '<div class="row"><label for="warehouse-field">'+this.warehouseLabel+':</label> '+
            '<select class="status-select" name="warehouse_id">'+
                raktar
            +'</select>'+
        '</div>'; 

        var saleTargetDropdown = '<div class="row"><label for="sale-target-field">'+this.saleTargetLabel+':</label> '+
            '<select class="sale-target-select" name="sale_target">'+
                ertHely
            +'</select>'+
        '</div>';
        
        var utilizerDropdown = '<div class="row"><label for="utilizer-field">'+this.utilizerLabel+':</label> '+
            '<select class="utilizer-select" name="utilizer">'+
                utilizers
            +'</select>'+
        '</div>';

        var beszerArEgyseg = '<div class="row">'+
            '<select class="status-select" name="purchase_price_unit">'+
                purchasePriceUnit
            +'</select>'+
        '</div>'; 

        var ertArEgyseg = '<div class="row">'+
            '<select class="status-select" name="sell_price_unit">'+
                sellPriceUnit
            +'</select>'+
        '</div>'; 

        
        var purchase_price = sale.purchase_price != undefined ? sale.purchase_price : '';
        var sell_price = sale.sell_price != undefined ? sale.sell_price : '';

        var beszerAr = '<div class="row"><label for="purchase-price">'+this.purchasePriceLabel+'</label><br/><input type="text" value="'+purchase_price+'" class="field purchase-price" id="purchase-price" name="purchase_price" /></div>';
        
        var ertAr = '<div class="row"><label for="sell-price">'+this.sellPriceLabel+'</label><br/><input type="text" value="'+sell_price+'" class="field sell-price" id="sell-price" name="sell_price" /></div>';        

        var annex = '<div class="file-row"><label for="annex">'+this.annexLabel+'</label><br/>'+
        (shipment.annex_url ? '<a target="_blank" href="'+shipment.annex_url+'">'+this.downloadLabel+'</a><br/>' : '') +
        '<input type="file" data-type="annex-field" id="annex-remodal" class="annex file-remodal" name="annex-file" /></div>';

        var szallitolevel = '<div class="file-row"><label for="szallitolevel">'+this.szallitolevelLabel+'</label><br/>'+
        (shipment.szallitolevel_url ? '<a target="_blank" href="'+shipment.szallitolevel_url+'">'+this.downloadLabel+'</a><br/>' : '') +
        '<input type="file" data-type="szallitolevel-field" id="szallitolevel-remodal" class="szallitolevel file-remodal" name="szallitolevel-file" /></div>';

        var cmr = '<div class="file-row"><label for="cmr">'+this.cmrLabel+'</label><br/>'+
        (shipment.cmr_url ? '<a target="_blank" href="'+shipment.cmr_url+'">'+this.downloadLabel+'</a><br/>' : '') +
        '<input type="file" data-type="cmr-field" id="cmr-remodal" class="cmr file-remodal" name="cmr-file" /></div>';

        var merlegjegy = '<div class="file-row"><label for="merlegjegy">'+this.merlegjegyLabel+'</label><br/>'+
        (shipment.merlegjegy_url ? '<a target="_blank" href="'+shipment.merlegjegy_url+'">'+this.downloadLabel+'</a><br/>' : '') +
        '<input type="file" data-type="merlegjegy-field" id="merlegjegy-remodal" class="merlegjegy file-remodal" name="merlegjegy-file" /></div>';

        var anyagkisero = '<div class="file-row"><label for="anyagkisero">'+this.anyagkiseroLabel+'</label><br/>'+
        (shipment.anyagkisero_url ? '<a target="_blank" href="'+shipment.anyagkisero_url+'">'+this.downloadLabel+'</a><br/>' : '') +
        '<input type="file" data-type="anyagkisero-field" id="anyagkisero-remodal" class="anyagkisero file-remodal" name="anyagkisero-file" /></div>';


        /** hiddens */

        var hiddenSzallitolevel = '<input type="hidden"  name="szallitolevel-field" />';

        var hiddenCmr = '<input type="hidden"  name="cmr-field" />';

        var hiddenAnnex = '<input type="hidden"  name="annex-field" />';

        var hiddenMerlegjegy = '<input type="hidden"  name="merlegjegy-field" />';

        var hiddenAnyagkisero = '<input type="hidden"  name="anyagkisero-field" />';

        var hiddenMaterialIds = '<input type="hidden"  name="material_ids" value="'+shipment.material_ids+'" />';

        /** hiddens end */

        var button = '<div class="button-row"><button class="button" id="savebutton-remodal" name="savebutton_remodal" type="button">'+this.saveButton+'</button></div>';

        form.append(partner);
        form.append(location);
        form.append(material);
        form.append(date);
        form.append(plate);
        form.append(reg_number);
        
        form.append(statusDropdown);
        form.append(transportDropdown);
        form.append(warehouseDropdown);
        form.append(saleTargetDropdown);
        form.append(utilizerDropdown);

        form.append(beszerAr);
        form.append(beszerArEgyseg);
        form.append(ertAr);
        form.append(ertArEgyseg);


        form.append(comment);

        form.append(annexNo);
        form.append(shippingNo);
        form.append(balanceNumber);        
        form.append(cmrNo);
        form.append(sentWeight);
        form.append(baleNum);
        
        
        form.append(annex);
        form.append(szallitolevel);
        form.append(cmr);
        form.append(merlegjegy);
        form.append(anyagkisero);

        form.append(hiddenSzallitolevel);
        form.append(hiddenCmr);
        form.append(hiddenAnnex);
        form.append(hiddenMerlegjegy);
        form.append(hiddenAnyagkisero);
        form.append(hiddenMaterialIds);
        form.append(button);

        form_container.html('');

        form_container.append(closeButton);
        form_container.append(form);

        this.remodal.open(); 

        form.find('.date-field').datepicker({ dateFormat: "yy-mm-dd", firstDay: 1});
    },
    uploadFile: function(files, type) {
        var data = new FormData();
        data.append('file', files[0]);
        var _self = this;
        _self.notyf.confirm('Feltöltés folyamatban');
        $('#savebutton-remodal').fadeOut(300);
        
        $.ajax({
            url:ajax_controller+'uploadTmpImage/'+frontend_lang+'/'+type,
            type:'post',
            dataType:'json',
            data: data,
            cache: false,
            contentType: false,
            processData: false,
            success: function(response) {
                
                if (response.success) {
                    $('[name="'+type+'"]').val(response.tmp_img);
                    _self.notyf.confirm(response.message);
                    $('#savebutton-remodal').fadeIn(300);
                } else {
                    _self.notyf.alert(response.error);
                }
            }
        });
        
    },
    sendForm: function(form) {
        var _self = this;

        if (!this.sendedForm) {
            _self.sendedForm = true;
            $.ajax({
                url:form.attr('action'),
                dataType:'json',
                type:'post',
                data: form.serialize(),
                success: function(response) {
                    if (response.success) {
                        if (typeof response.reload_url !== 'undefined' && response.reload_url != '') {
                            document.location.href = response.reload_url;
                        }
                    } else {
                        _self.notyf.alert(response.msg);
                        setTimeout(function(){
                            _self.sendedForm = false;
                        }, 1000);
                        if (typeof response.reload_url !== 'undefined' && response.reload_url != '') {
                            document.location.href = response.reload_url;
                        }
                    }
                }
            });
        }
    }

}