function TransportRegister (container, params) {
    try {
        this.container = container;

        if (params) {
            this.setParams(params);
        }

        this.init();

    } catch (error) {
        console.error(error);
    }
}

TransportRegister.prototype = {
    container: undefined,
    plateNumberLabel: undefined,
    transportDateLabel: undefined,
    form: undefined,
    notif: undefined,
    sendedForm: false,
    transports:{},
    transportDataField: false,
    transportDataPackage: false,
    transportDataFrom: false,
    transportDataTo: false,
    remodal: undefined,
    formRemodal: undefined,
    transportModItems: {},
    statusLabel: undefined,
    toLabel: undefined,
    fromLabel: undefined,
    materialLabel: undefined,
    dateField: undefined,
    transportDateRow: undefined,
    transportDataComment: undefined,
    init: function() {
        this.setElements();
        this.addDOMEvents();  
        this.remodal = $('[data-remodal-id=transport-remodal]').remodal();      
    },
    setElements: function() {
        this.notyf = new Notyf({
            delay:5000
        });
        this.form = this.container.find('#timebooking-form');
        this.dateField = this.container.find('.transport-date');
        this.transportDateRow = this.container.find('.transport-date-row');
    },
    addDOMEvents: function() {
        var _self = this;
        this.setTransportItem();

        $(document).on('click', '#savebutton', function(){
            _self.sendForm(_self.form);
        });

        $(document).on('click', '#savebutton-remodal', function(){
            _self.sendForm(_self.formRemodal);
        });

        this.container.find('[data-purpose="transport-modify-button"]').click(function(){
            _self.openTransportItem($(this).data('transport-id'));
            _self.formRemodal = $('#transport-remodal-form');
        });

        this.dateField.datepicker({
            dateFormat: "yy-mm-dd",
            minDate: 'today',
            firstDay: 1,
            onSelect: function() {
                var tmp = _self.container.find('.form-bottom-container');
                if (!tmp || (tmp && tmp.length === 0)) {
                    _self.makeFormBottom();
                }
            }
        });

    },
    setParams: function(params) {
        if (params.plateNumberLabel) {this.setplateNumberLabel(params.plateNumberLabel);}
        if (params.transportDateLabel) {this.settransportDateLabel(params.transportDateLabel);}
        if (params.saveButton) {this.setsaveButton(params.saveButton);}
        if (params.transports) {this.setTransports(params.transports);}
        if (params.transportModItems) {this.setTransportModItems(params.transportModItems);}

        if (params.fromLabel) {this.setFromLabel(params.fromLabel);}
        if (params.toLabel) {this.setToLabel(params.toLabel);}
        if (params.statusLabel) {this.setstatusLabel(params.statusLabel);}
        if (params.materialLabel) {this.setmaterialLabel(params.materialLabel);}

        if (params.transportDataField) {this.settransportDataField(params.transportDataField);}
        if (params.transportDataPackage) {this.settransportDataPackage(params.transportDataPackage);}
        if (params.transportDataFrom) {this.settransportDataFrom(params.transportDataFrom);}
        if (params.transportDataTo) {this.settransportDataTo(params.transportDataTo);}
        if (params.transportDataComment) {this.settransportDataComment(params.transportDataComment);}

    },
    setplateNumberLabel: function(plateNumberLabel) {
        this.plateNumberLabel = plateNumberLabel;
    },
    setFromLabel: function(fromLabel) {
        this.fromLabel = fromLabel;
    },
    setToLabel: function(toLabel) {
        this.toLabel = toLabel;
    },
    setstatusLabel: function(statusLabel) {
        this.statusLabel = statusLabel;
    },
    setmaterialLabel: function(materialLabel) {
        this.materialLabel = materialLabel;
    },
    settransportDateLabel: function(transportDateLabel) {
        this.transportDateLabel = transportDateLabel;
    },
    settransportDataField: function(transportDataField) {
        this.transportDataField = transportDataField;
    },
    settransportDataPackage: function(transportDataPackage) {
        this.transportDataPackage = transportDataPackage;
    },
    settransportDataFrom: function(transportDataFrom) {
        this.transportDataFrom = transportDataFrom;
    },
    settransportDataTo: function(transportDataTo) {
        this.transportDataTo = transportDataTo;
    },
    settransportDataComment: function(transportDataComment) {
        this.transportDataComment = transportDataComment;
    },
    setsaveButton: function(saveButton) {
        this.saveButton = saveButton;
    },
    setTransports: function(transports) {
        this.transports = transports;
    },
    setTransportModItems: function(transportModItems) {
        this.transportModItems = transportModItems;
    },
    setTransportItem: function() {
        var _self = this;

        var form = this.container.find('#timebooking-form');

        $(document).on('change', '#transport-item-dropdown', function(){
            
            var value = $(this).val();

            var transport = _self.transports[value];

            var transportDatas = 
            '<div class="row"><label>'+_self.transportDataField+' </label>'+
            '<br/><label>'+_self.transportDataPackage+': </label>'+
            '<span>'+transport.package+'</span>'+
            '<br/><label>'+_self.transportDataFrom+': </label>'+
            '<span>'+transport.from+'</span>'+
            '<br/><label>'+_self.transportDataTo+': </label>'+
            '<span>'+transport.to+'</span>'+
            '<br/><label>'+_self.transportDataComment+': </label>'+
            '<span>'+transport.comment+'</span>'+
            '</div>';

            $('.transport-datas').html(transportDatas);
            _self.transportDateRow.show();
            $('.transport-datas').show();


            _self.dateField.datepicker('option', 'minDate', transport.available);      
        });

    },
    makeFormBottom: function() {
        var outerDiv = $('<div>', {
            class: 'form-bottom-container'
          }); 
        
        var plate = '<div class="row"><label for="plate-number">'+this.plateNumberLabel+'</label><br/><input type="text" class="field plate-number" id="plate-number" name="plate_number" /></div>';
        var transporter_comment = '<div class="row"><label for="transporter-comment">'+this.transportDataComment+'</label><br/><input type="text" class="field transporter-comment" id="transporter-comment" name="transporter_comment" /></div>';
        var button = '<button class="button" id="savebutton" name="savebutton" type="button">'+this.saveButton+'</button>';

        outerDiv.append(plate);
        outerDiv.append(transporter_comment);
        outerDiv.append(button);

        this.form.append(outerDiv);
    },
    sendForm: function(form) {
        var _self = this;

        if (!this.sendedForm) {
            _self.sendedForm = true;
            $.ajax({
                url:form.attr('action'),
                dataType:'json',
                type:'post',
                data: form.serialize(),
                success: function(response) {
                    if (response.success) {
                        if (typeof response.reload_url !== 'undefined' && response.reload_url != '') {
                            document.location.href = response.reload_url;
                        }
                    } else {
                        _self.notyf.alert(response.msg);
                        setTimeout(function(){
                            _self.sendedForm = false;
                        }, 500);
                    }
                }
            });
        }
    },
    openTransportItem: function(id) {
        var _self = this;
        var transport = typeof this.transportModItems[id] === 'object' ? this.transportModItems[id] : undefined;
        if (transport) {
            _self.createRemodal(transport);
        } else {
            console.log('HANGYÁL');
        }
    },
    createRemodal: function(transport) {

        var form_container = $('.transport-remodal');

        var form = $('<form id="transport-remodal-form" action="'+base_url+'save-transport-remodal/'+transport.id+'">');

        var closeButton = '<button data-remodal-action="close" class="remodal-close"></button>';
        var from = '<div class="row"><label for="location-field">'+this.fromLabel+':</label> <span class="load-field" id="location-field">'+transport.from+'</span></div>';
        var to = '<div class="row"><label for="location-field">'+this.toLabel+':</label> <span class="load-field" id="location-field">'+transport.to+'</span></div>';

        var status = '<div class="row"><label for="status-field">'+this.statusLabel+':</label> <span class="load-field" id="status-field">'+transport.status+'</span></div>';

        var material = '<div class="row"><label for="materials-field">'+this.materialLabel+':</label> <span class="load-field" id="materials-field">'+transport.materials+'</span></div>';

        // var date = '<div class="row"><label for="date-field">'+this.transportDateLabel+':</label> <span class="load-field" id="date-field">'+transport.date+'</span></div>';

        var date = '<div class="row"><label for="date-field">'+this.transportDateLabel+'*:</label> <input type="text" value="'+transport.date+'" class="field date-field" id="date-field" name="transport_date" /></div>';        

        var comment = '<div class="row"><label for="date-field">'+this.transportDataComment+':</label> <span class="load-field" id="comment-field">'+transport.comment+'</span></div>';
        var transporter_comment = '<div class="row"><label for="transporter-comment">'+this.transportDataComment+'</label><br/><textarea type="text" id="transporter-comment" class="field transporter_comment" name="transporter_comment">'+transport.transporterComment+'</textarea></div>';
        var plate = '<div class="row"><label for="plate-number">'+this.plateNumberLabel+'</label><br/><input type="text" value="'+transport.plate_number+'" class="field plate-number" id="plate-number" name="plate_number" /></div>';

        var button = '<div class="button-row"><button class="button" id="savebutton-remodal" name="savebutton_remodal" type="button">'+this.saveButton+'</button></div>';

        form.append(from);
        form.append(to);
        form.append(status);
        form.append(material);
        
        form.append(comment);        
        form.append(date);           
        form.append(plate);
        form.append(transporter_comment);
        
        form.append(button);

        form.find('.date-field').datepicker({ dateFormat: "yy-mm-dd", firstDay: 1});

        form_container.html('');

        form_container.append(closeButton);
        form_container.append(form);
        
        this.remodal.open();
    }
}