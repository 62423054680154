window.loaded = false;
var windowWidth = 0;
var headerSlider = undefined;

$(window).on('load', function(){
	window.loaded = true;
});


$(document).ready(function() {
	/* MOBILE */
	//create js hook links

	$('.mailtolink').click(function(e) {
		handleMailtoLink($(this));
	});

	
	$('[data-purpose="remodal-open"]').click(function(){
		var target = $(this).data('popup-target');
		if ($(this).data('popup-target')) {
			var item = $('[data-remodal-id="' + $(this).data('popup-target') + '"]');
			if (item.length > 0) {
				item.remodal().open();
			}
		}
	});
	
	if( $('.cookie-alert-outer').length > 0 ) {
		setTimeout(function(){
			var height = $('.cookie-alert-outer .content-width').outerHeight();
			$('.cookie-alert-outer').animate({'height':height+'px'}, 600);
		}, 550);

		var alertResize = debounce(function(){
			if(!$('.cookie-alert-outer').hasClass('closed')){
				var height = $('.cookie-alert-outer .content-width').outerHeight();
				$('.cookie-alert-outer').height(height);
			}
		}, 50);

		window.addEventListener('resize', alertResize);

		$('.close-cookie-alert').click(function(){
			$('.cookie-alert-outer').animate({'height':'0px'}, 600);
			$('.cookie-alert-outer').addClass('closed');

			$.ajax({
				url:ajax_controller+'setCookieConfirm',
				dataType:'json',
				type:'post',
				data:{cookie:1},
				success: function(){}
			});
		});
	}

	$('[data-purpose="remodal-close"]').click(function(){
		var value = $(this).data('value');
		if (value && value == 'confirm') {
			$('#terms-chk').attr('checked', true);
		}

		$(this).parents('.remodal').remodal().close();
	});

	$('[data-purpose="scroll-to-element"]').click(function(){
		var target = $(this).data('target');
		var item = $(target);
		
		if (item.length > 0) {
			if ($(this).hasClass('mobile-menu-item')) {
				mobile_menu.changeMenuVisibility();
			}

			$('html, body').animate({scrollTop: item.offset().top}, 400);
		}
	});

	$('[data-purpose="remodal-open"]').click(function(){
		var target = $(this).data('target');
		if (target) {
			openRemodalContent(target);
		}
	});

	$('[data-purpose="privacy-confirm-button"]').click(function(){
		var type = $(this).data('type');
		$('#privacyChk').prop('checked', type == 'accept');
		$('[data-remodal-id="privacyRemodal"]').remodal().close();
	});

	$('[data-purpose="logout-button"]').click(function(){
		var type = $(this).data('type');
		
		$.ajax({
			url: ajax_controller+'logout',
			dataType:'json',
			type:'post',
			data: {
				type: type
			},
			success: function(response) {
				document.location.reload();
			}
		})
	});

	$('.remodal-open-button').click(function(){
		var target = $(this).data('popup-id');
		if (target) {
			var item = $('[data-remodal-id="'+target+'"]');
			if (item.length == 1) {
				item.remodal().open();
			}
		}
	});

	$('[data-purpose="save-pass-button"]').click(function(){
		var _self = this;
		var sendedForm = false;

		if (!sendedForm) {
			sendedForm = true;
			var data = {
				lang: frontend_lang
			};

			var notyf = new Notyf({
                delay:5000
            });

			var form = $('#forgot-pass-form');

			console.log(form);

			form.serializeArray().forEach(function(field){
				data[field.name] = field.value
			});
			$.ajax({
				url:form.attr('action'),
				dataType:'json',
				type:'post',
				data: data,
				beforeSend: function() {
					form.find('.has-error').removeClass('has-error');
				},
				success: function(response) {
					if (response.success) {
						if ((response.msg).trim() !== '') {
							notyf.confirm(response.msg);
						}
						setTimeout(function(){
							window.location.href = response.url;
						}, 2000);
					} else {
						if (typeof response.errors == 'object') {
							$.each(response.errors, function(key, value) {
								if (value.trim() !== '') {
									notyf.alert(value);
								}

								var input = form.find('[name="'+key+'"]');
								if (input.length > 0) {
									input.addClass('has-error');
								}
							});
						}

						setTimeout(function() {
							sendedForm = false;
						}, 500);
					}
				},
			});
		}
	});
});

function openRemodalContent(remodal_id) {
	var tmp = $('[data-remodal-id="'+remodal_id+'"]');
	if(tmp.length > 0){
	  tmp.remodal().open();
	}
}

function debounce(func, wait, immediate) {
    var timeout;
    return function() {
        var context = this, args = arguments;
        var later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

function sendAnalyticsEvent(action, category, tag){
	if(typeof ga != 'undefined' && action && category){
		if(tag && tag.trim() != ''){
			ga('send', 'event', action, category, tag);
		}else{
			ga('send', 'event', action, category);
		}
	}
}


function svg(id) {
	return '<span class="svg"><svg class="icon ' + id + '"><use xlink:href="#' + id + '" /></svg></span>';
}


function handleMailtoLink(th) {
	if (th.attr('href') == 'javascript:void(0)') {
		var hidden = th.children('.emailhidden');
		th.attr('href', 'mailto:' + hidden.data('user') + '@' + hidden.data('domain'));
	}
}


function initSlider()
{
	headerSlider = $('#headerSlider').bxSlider({
		auto:true,
		pause:10000,
		infiniteLoop: true,
		touchEnabled: true,
		preventDefaultSwipeX: true,
		preventDefaultSwipeY: false,
	});
}

function loadJs(url, implementationCode){
    var scriptTag = document.createElement('script');
    scriptTag.src = url;

    scriptTag.onload = implementationCode;
    scriptTag.onreadystatechange = implementationCode;

    document.body.appendChild(scriptTag);
};

function getMapStyle()
{
	return [
		{
			"featureType": "water",
			"elementType": "geometry",
			"stylers": [
				{
					"color": "#e9e9e9"
				},
				{
					"lightness": 17
				}
			]
		},
		{
			"featureType": "landscape",
			"elementType": "geometry",
			"stylers": [
				{
					"color": "#f5f5f5"
				},
				{
					"lightness": 20
				}
			]
		},
		{
			"featureType": "road.highway",
			"elementType": "geometry.fill",
			"stylers": [
				{
					"color": "#ffffff"
				},
				{
					"lightness": 17
				}
			]
		},
		{
			"featureType": "road.highway",
			"elementType": "geometry.stroke",
			"stylers": [
				{
					"color": "#ffffff"
				},
				{
					"lightness": 29
				},
				{
					"weight": 0.2
				}
			]
		},
		{
			"featureType": "road.arterial",
			"elementType": "geometry",
			"stylers": [
				{
					"color": "#ffffff"
				},
				{
					"lightness": 18
				}
			]
		},
		{
			"featureType": "road.local",
			"elementType": "geometry",
			"stylers": [
				{
					"color": "#ffffff"
				},
				{
					"lightness": 16
				}
			]
		},
		{
			"featureType": "poi",
			"elementType": "geometry",
			"stylers": [
				{
					"color": "#f5f5f5"
				},
				{
					"lightness": 21
				}
			]
		},
		{
			"featureType": "poi.park",
			"elementType": "geometry",
			"stylers": [
				{
					"color": "#dedede"
				},
				{
					"lightness": 21
				}
			]
		},
		{
			"elementType": "labels.text.stroke",
			"stylers": [
				{
					"visibility": "on"
				},
				{
					"color": "#ffffff"
				},
				{
					"lightness": 16
				}
			]
		},
		{
			"elementType": "labels.text.fill",
			"stylers": [
				{
					"saturation": 36
				},
				{
					"color": "#333333"
				},
				{
					"lightness": 40
				}
			]
		},
		{
			"elementType": "labels.icon",
			"stylers": [
				{
					"visibility": "off"
				}
			]
		},
		{
			"featureType": "transit",
			"elementType": "geometry",
			"stylers": [
				{
					"color": "#f2f2f2"
				},
				{
					"lightness": 19
				}
			]
		},
		{
			"featureType": "administrative",
			"elementType": "geometry.fill",
			"stylers": [
				{
					"color": "#fefefe"
				},
				{
					"lightness": 20
				}
			]
		},
		{
			"featureType": "administrative",
			"elementType": "geometry.stroke",
			"stylers": [
				{
					"color": "#fefefe"
				},
				{
					"lightness": 17
				},
				{
					"weight": 1.2
				}
			]
		}
	];
}
