
function OrderRegister (container, params) {
    try {
        this.container = container;

        if (params) {
            this.setParams(params);
        }

        this.init();

    } catch (error) {
        console.error(error);
    }
}

OrderRegister.prototype = {
    container: undefined,
    plateNumberLabel: undefined,
    commentLabel: undefined,
    transportDateLabel: undefined,
    annexLabel: undefined,
    avisoLabel: undefined,
    cmrLabel: undefined,
    otherLabel: undefined,
    locationLabel: undefined,
    statusLabel: undefined,
    materialLabel: undefined,
    regNumberLabel: undefined,
    sendedForm: false,
    form: undefined,
    formRemodal: undefined,
    notyf: undefined,
    shipments: {},
    remodal: undefined,
    orderItemLocations:{},
    loadingTimeLabel: undefined,
    fromLabel: undefined,
    dateField: undefined,
    timebookingRow: undefined,
    transportDateRow: undefined,
    uploadedLabel: undefined,
    successUploaded: 0,
    startedUpload: 0,
    init: function() {
        this.setElements();
        this.addDOMEvents();
        this.remodal = $('[data-remodal-id=shipment-remodal]').remodal();        
    },
    setElements: function() {
        this.notyf = new Notyf({
            delay:5000
        });
        this.form = this.container.find('#timebooking-form');
        this.timebookingRow = this.container.find('.timebooking-loading-time');
        this.transportDateRow = this.container.find('.transport-date-row');
        this.dateField = this.transportDateRow.find('input');
    },
    addDOMEvents: function() {
        var _self = this;

        this.setOrderItem();

        $(document).on('click', '#savebutton', function(){
            _self.sendForm(_self.form);
        });

        $(document).on('click', '#savebutton-remodal', function(){
            _self.sendForm(_self.formRemodal);
        });

        $(document).on('change', '.file', function(){
            _self.uploadFile(this.files, $(this).data('type'));
        });

        $(document).on('change', '.file-remodal', function(){
            _self.uploadFile(this.files, $(this).data('type'));
        });

        this.container.find('[data-purpose="shipment-modify-button"]').click(function(){
            _self.openShipmentItem($(this).data('shipment-id'));
            _self.formRemodal = $('#timebooking-remodal-form');
        });

        this.dateField.datepicker({
            dateFormat: "yy-mm-dd",
            minDate: 'today',
            firstDay: 1,
            onSelect: function() {
                var tmp = _self.container.find('.form-bottom-container');
                if (!tmp || (tmp && tmp.length === 0)) {
                    _self.makeFormBottom();
                }
            }
        });
    },
    setParams: function(params) {
        if (params.plateNumberLabel) {this.setplateNumberLabel(params.plateNumberLabel);}
        if (params.commentLabel) {this.setcommentLabel(params.commentLabel);}
        if (params.transportDateLabel) {this.settransportDateLabel(params.transportDateLabel);}
        if (params.annexLabel) {this.setannexLabel(params.annexLabel);}
        if (params.avisoLabel) {this.setavisoLabel(params.avisoLabel);}
        if (params.cmrLabel) {this.setcmrLabel(params.cmrLabel);}
        if (params.otherLabel) {this.setotherLabel(params.otherLabel);}

        if (params.locationLabel) {this.setlocationLabel(params.locationLabel);}
        if (params.statusLabel) {this.setstatusLabel(params.statusLabel);}
        if (params.materialLabel) {this.setmaterialLabel(params.materialLabel);}
        if (params.regNumberLabel) {this.setregNumberLabel(params.regNumberLabel);}

        if (params.saveButton) {this.setsaveButton(params.saveButton);}
        if (params.shipments) {this.setShipments(params.shipments);}
        if (params.orderItemLocations) {this.setOrderItemLocations(params.orderItemLocations);}
        if (params.loadingTimeLabel) {this.setloadingTimeLabel(params.loadingTimeLabel);}
        if (params.fromLabel) {this.setfromLabel(params.fromLabel);}
        if (params.uploadedLabel) {this.setuploadedLabel(params.uploadedLabel);}
    },
    setlocationLabel: function(locationLabel) {
        this.locationLabel = locationLabel;
    },
    setstatusLabel: function(statusLabel) {
        this.statusLabel = statusLabel;
    },
    setmaterialLabel: function(materialLabel) {
        this.materialLabel = materialLabel;
    },
    setregNumberLabel: function(regNumberLabel) {
        this.regNumberLabel = regNumberLabel;
    },
    setplateNumberLabel: function(plateNumberLabel) {
        this.plateNumberLabel = plateNumberLabel;
    },
    setcommentLabel: function(commentLabel) {
        this.commentLabel = commentLabel;
    },
    settransportDateLabel: function(transportDateLabel) {
        this.transportDateLabel = transportDateLabel;
    },
    setannexLabel: function(annexLabel) {
        this.annexLabel = annexLabel;
    },
    setavisoLabel: function(avisoLabel) {
        this.avisoLabel = avisoLabel;
    },
    setcmrLabel: function(cmrLabel) {
        this.cmrLabel = cmrLabel;
    },
    setotherLabel: function(otherLabel) {
        this.otherLabel = otherLabel;
    },
    setsaveButton: function(saveButton) {
        this.saveButton = saveButton;
    },
    setShipments: function(shipments) {
        this.shipments = shipments;
    },
    setOrderItemLocations: function(orderItemLocations) {
        this.orderItemLocations = orderItemLocations;
    },
    setloadingTimeLabel: function(loadingTimeLabel) {
        this.loadingTimeLabel = loadingTimeLabel;
    },
    setfromLabel: function(fromLabel) {
        this.fromLabel = fromLabel;
    },
    setuploadedLabel: function(uploadedLabel) {
        this.uploadedLabel = uploadedLabel;
    },
    setOrderItem: function() {
        var _self = this;

        $(document).on('change', '#order-item-dropdown', function(){

            var value = $(this).val();
            var itemId = value.split('-');
            var loadingTime = _self.orderItemLocations[itemId[0]];
            
            var loadingTimeField = '<div class="row"><label>'+_self.loadingTimeLabel+'</label><br/><span>'+(loadingTime.location_load_time ? loadingTime.location_load_time : "-" )+'</span></div>';
            loadingTimeField += '<div class="row"><label>'+_self.fromLabel+'</label><br/><span>'+(loadingTime.from ? loadingTime.from : "-" )+'</span></div>';
            loadingTimeField += '<div class="row"><label>'+_self.commentLabel+'</label><br/><span>'+(loadingTime.comment ? loadingTime.comment : "-" )+'</span></div>';

            _self.timebookingRow.html(loadingTimeField);
            _self.timebookingRow.show();
            _self.transportDateRow.show();

            
            _self.dateField.datepicker('option', 'minDate', loadingTime.available);
        });

    },
    makeFormBottom: function() {

        var outerDiv = $('<div>', {
            class: 'form-bottom-container'
        }); 
        
        outerDiv.append('<div class="row"><label for="plate-number">'+(this.plateNumberLabel)+'</label><br/><input type="text" class="field plate-number" id="plate-number" name="plate_number" /></div>');
        outerDiv.append('<div class="row"><label for="comment">'+(this.commentLabel)+'</label><br/><textarea type="text" id="comment" class="field comment" name="comment" /></div>');

        /** File mezők */
        outerDiv.append('<div class="row"><label for="annex">'+(this.annexLabel)+'</label><br/><input type="file" data-type="annex-file" id="annex" class="annex file" name="annex-field" /></div>');
        outerDiv.append('<div class="row"><label for="aviso">'+(this.avisoLabel)+'</label><br/><input type="file" data-type="aviso-file" id="aviso" class="aviso file" name="aviso-field" /></div>');
        outerDiv.append('<div class="row"><label for="cmr">'+(this.cmrLabel)+'</label><br/><input type="file" data-type="cmr-file" id="cmr" class="cmr file" name="cmr-field" /></div>');
        outerDiv.append('<div class="row"><label for="other">'+(this.otherLabel)+'</label><br/><input type="file" data-type="other-file" id="other" class="other file" name="other-field" /></div>');
        /** File mezők vége */
        outerDiv.append('<button class="button" id="savebutton" name="savebutton" type="button">'+(this.saveButton)+'</button>');

        this.form.append(outerDiv);
        return false;
    },
    uploadFile: function(files, type) {
        var _self = this;
        var data = new FormData();
        data.append('file', files[0]);
        _self.notyf.confirm('Feltöltés folyamatban');
        $('#savebutton').fadeOut(300);
        $('#savebutton-remodal').fadeOut(300);

        _self.startedUpload++;
        
        $.ajax({
            url:ajax_controller+'uploadTmpImage/'+frontend_lang+'/'+type,
            type:'post',
            dataType:'json',
            data: data,
            cache: false,
            contentType: false,
            processData: false,
            success: function(response) {
                
                if (response.success) {
                    $('[name="'+type+'"]').val(response.tmp_img);                    
                    _self.notyf.confirm(response.message);
                    $('[data-type="'+type+'"]').parent().find('label').css('color','#6FBD24');
                    _self.successUploaded++;                    

                    if (_self.successUploaded == _self.startedUpload) {
                        $('#savebutton').fadeIn(300);
                        $('#savebutton-remodal').fadeIn(300);
                    }
                } else {                    
                    _self.notyf.alert(response.message);
                    $('[data-type="'+type+'"]').parent().find('label').css('color','#c21616');                    
                    _self.startedUpload--;
                }

                console.log(_self.successUploaded+' - '+ _self.startedUpload);
            }
        });
        
    },
    sendForm: function(form) {
        var _self = this;

        if (!this.sendedForm) {
            _self.sendedForm = true;
            $.ajax({
                url:form.attr('action'),
                dataType:'json',
                type:'post',
                data: form.serialize(),
                success: function(response) {
                    if (response.success) {
                        if (typeof response.reload_url !== 'undefined' && response.reload_url != '') {
                            document.location.href = response.reload_url;
                        }
                    } else {
                        _self.notyf.alert(response.msg);
                        setTimeout(function(){
                            _self.sendedForm = false;
                        }, 500);
                    }
                }
            });
        }
    },
    openShipmentItem: function(id) {
        var _self = this;
        var shipment = typeof this.shipments[id] === 'object' ? this.shipments[id] : undefined;
        if (shipment) {
            _self.createRemodal(shipment);
        } else {
            console.log('HANGYÁL');
        }
    },
    createRemodal: function(shipment) {

        this.startedUpload = 0;
        this.successUploaded = 0;
        console.log('itt');

        var form_container = $('.shipment-remodal');

        var form = $('<form id="timebooking-remodal-form" action="'+base_url+'save-timebooking-remodal/'+shipment.id+'">');

        var closeButton = '<button data-remodal-action="close" class="remodal-close"></button>';
        var location = '<div class="row"><label for="location-field">'+this.locationLabel+'</label> <span class="load-field" id="location-field">'+shipment.location+'</span></div>';

        var status = '<div class="row"><label for="status-field">'+this.statusLabel+'</label> <span class="load-field" id="status-field">'+shipment.status+'</span></div>';

        var material = '<div class="row"><label for="materials-field">'+this.materialLabel+'</label> <span class="load-field" id="materials-field">'+shipment.materials+'</span></div>';

        // var date = '<div class="row"><label for="date-field">'+this.transportDateLabel+':</label> <span class="load-field" id="date-field">'+shipment.date+'</span></div>';

        var date = '<div class="row"><label for="date-field">'+this.transportDateLabel+'*:</label> <input type="text" value="'+shipment.date+'" class="field date-field" id="date-field" name="date" /></div>';        

        var plate = '<div class="row"><label for="plate-number">'+this.plateNumberLabel+'</label><br/><input type="text" value="'+shipment.plate_number+'" class="field plate-number" id="plate-number" name="plate_number" /></div>';

        var reg_number = '<div class="row"><label for="date-field">'+this.regNumberLabel+'</label> <span class="load-field" id="date-field">'+shipment.reg_number+'</span></div>';

        var admin_comment = '<div class="row"><label for="comment">'+this.commentLabel+'</label><br/><span class="load-field" id="materials-field">'+shipment.admin_comment+'</span></div>';

        var comment = '<div class="row"><label for="comment">'+this.commentLabel+'</label><br/><textarea type="text" id="comment" class="field comment" name="comment">'+shipment.comment+'</textarea></div>';

        var annex = '<div class="row annex-row"><label for="annex">'+this.annexLabel+'</label><br/><input type="file" data-type="annex-field" id="annex-remodal" class="annex file-remodal" name="annex-file" /></div>';        

        var aviso = '<div class="row aviso-row"><label for="aviso">'+this.avisoLabel+'</label><br/><input type="file" data-type="aviso-field" id="aviso-remodal" class="aviso file-remodal" name="aviso-file" /></div>';

        var cmr = '<div class="row cmr-row"><label for="cmr">'+this.cmrLabel+'</label><br/><input type="file" data-type="cmr-field" id="cmr-remodal" class="cmr file-remodal" name="cmr-file" /></div>';

        var other = '<div class="row other-row"><label for="other">'+this.otherLabel+'</label><br/><input type="file" data-type="other-field" id="other-remodal" class="other file-remodal" name="other-file" /></div>';

        var uploaded_files = '<div class="row"><label>'+this.uploadedLabel+'</label><br/><span class="load-field">'+shipment.uploaded_files+'</span></div>';

        /** hiddens */

        var hiddenAviso = '<input type="hidden"  name="aviso-field" />';

        var hiddenCmr = '<input type="hidden"  name="cmr-field" />';

        var hiddenAnnex = '<input type="hidden"  name="annex-field" />';

        var hiddenOther = '<input type="hidden"  name="other-field" />';

        /** hiddens end */

        var button = '<div class="button-row"><button class="button" id="savebutton-remodal" name="savebutton_remodal" type="button">'+this.saveButton+'</button></div>';

        

        form.append(location);
        form.append(status);
        form.append(material);
        form.append(date);
        form.append(reg_number);
        form.append(admin_comment);
        form.append(plate);
        form.append(comment);
        form.append(uploaded_files);
        form.append(annex);
        form.append(aviso);
        form.append(cmr);
        form.append(other);
        form.append(hiddenAviso);
        form.append(hiddenCmr);
        form.append(hiddenAnnex);
        form.append(hiddenOther);
        form.append(button);

        form.find('.date-field').datepicker({ dateFormat: "yy-mm-dd", firstDay: 1});

        form_container.html('');

        form_container.append(closeButton);
        form_container.append(form);        

        this.remodal.open();
    }

}